import React, { Component } from "react";
// Customizable Area Start

import {
    Box,
    Button,
    Paper, Typography
} from "@material-ui/core";
import Accordion from '@material-ui/core/Accordion';
import AccordionDetails from '@material-ui/core/AccordionDetails';
import AccordionSummary from '@material-ui/core/AccordionSummary';
import Backdrop from '@material-ui/core/Backdrop';
import Card from '@material-ui/core/Card';
import CircularProgress from '@material-ui/core/CircularProgress';
import { createTheme, makeStyles } from "@material-ui/core/styles";
import ExpandMoreIcon from '@material-ui/icons/ExpandMore';
import PlayCircleFilledIcon from '@material-ui/icons/PlayCircleFilled';
import PlayCircleOutlineIcon from '@material-ui/icons/PlayCircleOutline';
import ShoppingCartOutlinedIcon from '@material-ui/icons/ShoppingCartOutlined';
import Rating from '@material-ui/lab/Rating';
import './videostyles.css';
import Grid from '@material-ui/core/Grid';
import LinearProgress from '@material-ui/core/LinearProgress';
import {iconImage, iconPage, iconQuiz, iconVideo, iconMusic, frame, viewShape} from "./assets";
import RecommendedCourses from "../../../components/src/RecommendedCourses";
import './CoursePage.css';
const group = require("../assets/Group 8.png")

const useStyles = makeStyles((theme) => ({
    root: {
        display: 'flex',
        marginBottom: "10px",
        border: '1px solid #E5E2E1',
        borderRadius: '8px'
    },
    details: {
        display: 'flex',
        flexDirection: 'column',
    },
    content: {
        flex: '1 0 auto',
    },
    controls: {
        display: 'flex',
        alignItems: 'center',
        paddingLeft: theme.spacing(1),
        paddingBottom: theme.spacing(1),
    },
    playIcon: {
        height: 38,
        width: 38,
    },
}));

const theme = createTheme({
    palette: {
        primary: {
            main: "#fff",
            contrastText: "#fff",
        },
    },
    typography: {
        h6: {
            fontWeight: 500,
        },
        subtitle1: {
            margin: "20px 0px",
        },
    },
});


import CoursePageController, { Props } from "./CoursePageController.web";
import { styled } from "@material-ui/styles";
import { fontSize } from "@mui/system";
// Customizable Area End
let id: any;
export default class CoursePage extends CoursePageController {
    // Customizable Area Start
    // Customizable Area End

    render() {
        id = this.state.allCourseLibraryData?.data;

        const handleApi = () => {
            if (this.state.allCourseLibraryData?.data?.attributes?.button === "enroll") {
                this.postXpandLibraryData(this.state.allCourseLibraryData?.data?.id)
            }
            else if (this.state.allCourseLibraryData?.data?.attributes?.button === "cart") {
                this.addCartItem(this.state.allCourseLibraryData?.data?.id, this.state.allCourseLibraryData?.data?.type)
            } else if (this.state.allCourseLibraryData?.data?.attributes?.button === "start") {
                this.startPlay(this.state.allCourseLibraryData.data)

            }
        }
        let videoTime = parseFloat(this.state.allCourseLibraryData?.data?.attributes?.total_video_length).toFixed(0);
        const totalSeconds: any = videoTime;
        const hours = Math.floor(totalSeconds / 3600);
        const minutes = Math.floor(totalSeconds / 60);
        const seconds = totalSeconds % 60;

        function padTo2Digits(num: any) {
            return num.toString().padStart(2, '0');
        }
        const result = `${padTo2Digits(hours)}h ${padTo2Digits(minutes)}m ${padTo2Digits(seconds)}s`;
        return (
            // Customizable Area Start
            <Grid container style={styles.profileHeaderGrid}>
                <Grid item lg={12} md={12} sm={12} xs={12}>
                    <Typography style={styles.profileHeader} variant="h3" className="profile_header" data-test-id ="headerDataId"> 
                    <img src={viewShape} style={{padding:"0px 10px 0px 10px", width:"32px", height: "22px"}}/>
                    {this.state.allCourseLibraryData?.data?.attributes?.title}
                    </Typography>
                    <Backdrop style={styles.backdrop} open={this.state.open} >
                        <CircularProgress color="inherit" />
                    </Backdrop>
                    <Grid container spacing={2} style={styles.mainGridBox} key={this.state.allCourseLibraryData?.data?.id}>
                        <Grid item lg={8} md={8} sm={12} xs={12}>
                            <Box
                                style={styles.mainImg_box}>
                                <img src={this.state.allCourseLibraryData?.data?.attributes?.thumbnail_image?.url} style={styles.main_courseImg} alt="img" />
                            </Box>
                            <Box style={styles.main_titleBox}>
                                <p
                                    style={styles.main_title}>
                                   </p>
                            </Box>
                            <Grid container style={styles.mainGridButton}>
                                <Grid item lg={3} md={3} sm={4} xs={6}>
                                    <Typography style={styles.create_typo}>Created By</Typography>
                                    <Box style={styles.boxCreate}>{this.state.allCourseLibraryData?.data?.attributes?.created_by}</Box>
                                </Grid>
                                <Grid item lg={3} md={3} sm={4} xs={6}>
                                    <Typography style={styles.cateTypo}>Category</Typography>
                                    <Box style={styles.box_cate}>{this.state.allCourseLibraryData?.data?.attributes?.categories} </Box>
                                </Grid>
                                <Grid item lg={3} md={3} sm={4} xs={6} style={styles.rattingGrid}>
                                    <Typography style={styles.rattingTypo}>Ratings</Typography>
                                    <Box style={styles.rattingBox}>
                                        <span style={styles.ratting}>{this.state.allCourseLibraryData?.data?.attributes?.average_rating}</span>
                                        <Rating
                                            name='read-only'
                                            value={this.state.allCourseLibraryData?.data?.attributes?.average_rating.toFixed(2)}
                                            // value={this.state.currRating}
                                            readOnly
                                        />
                                    </Box>
                                </Grid>
                                <Grid item lg={3} md={3} sm={4} xs={6} >
                                    {this.state.allCourseLibraryData?.data?.attributes?.button === "start" ?
                                        <Button id="start-course-id-tst" onClick={() => handleApi()}
                                            startIcon={<PlayCircleFilledIcon />}
                                            style={styles.start_button}
                                        >Start </Button>
                                        : <>{
                                            this.state.allCourseLibraryData.data?.attributes?.button === "subscription"
                                                ?
                                                <Button
                                                    id="start-course-id-tst1"
                                                    onClick={() => this.addCartItem(this.state.allCourseLibraryData?.data?.id, this.state.allCourseLibraryData?.data?.type)}
                                                    style={styles.course_buy}
                                                >Buy Subscription ${this.state.allCourseLibraryData?.data?.attributes?.price}</Button> :
                                                <Button
                                                    id="start-course-id-tst2"
                                                    onClick={() => handleApi()}
                                                    startIcon={this.state.allCourseLibraryData?.data?.attributes?.button === "enroll" ? <></> : <ShoppingCartOutlinedIcon />}
                                                    style={styles.course_start}
                                                >{this.state.allCourseLibraryData?.data?.attributes?.button === "enroll" ? <><img src={group} /></> : <>${this.state.allCourseLibraryData?.data?.attributes?.price}</>}</Button>
                                        }</>
                                    }

                                </Grid>
                            </Grid>
                            <p
                                style={styles.data_detailP}>
                                Description</p>
                            <p style={styles.data_detail_main}>{this.state.allCourseLibraryData?.data?.attributes?.description}</p>
                            <p style={styles.data_detail}>{this.state.allCourseLibraryData?.data?.attributes?.learning_details}</p>
                        </Grid>
                        <Grid item lg={4} md={4} sm={12} xs={12}>
                            <Paper elevation={4} style={styles.sidebar_paper}>
                              
                               {
                                !this.state.allCourseLibraryData?.data?.attributes.content_release_schedule_value  && 
                                 <>
                               
                                <StyledTabs>
                                    <StyledTab className={this.state.activeTab === 0 ? "activeTab": ""}>Lessons</StyledTab>
                                    <StyledTab  className={this.state.activeTab === 1 ? "activeTab": ""}>Notes</StyledTab>
                                </StyledTabs>
                                <ProgressContainer>
                                <ProgressTop>
                                    <CourseSetting>Course Completion</CourseSetting>
                                    <CourseSettingAfter>Due in 40 days</CourseSettingAfter>
                                </ProgressTop>
                                <Box><StyledProgressBar variant="determinate" value={0} />
</Box>
                                <ProgressBottom>
                                    <LessonsFont>0 of 15 lessons completed</LessonsFont>
                                </ProgressBottom>
                               </ProgressContainer>
                               </>
                                }

                            
                                <Box style={styles.main_Accordion}>
                                    {
                                        this.state.allCourseLibraryData?.data?.attributes.content_release_schedule_value  &&
                                        <>
                                         <h2 style={styles.mainh2_sidebar}>Course includes</h2>
                                    <BoxDataAlign>
                                        <Box>
                                        <ImageWithData><span><img src={frame} height="48px" width ="49px"/></span><LessonCountWithText><span style={{ fontWeight: 700, fontSize: "18px" }}>{this.state.allCourseLibraryData.data.attributes.lesson_count}</span> <span className="demo">Lessons</span> </LessonCountWithText></ImageWithData>
                                        </Box>

                                        <SecondBox>
                                        <Box><Divider></Divider></Box>

                                        <AfterCountLesson>
                                            <Typography className="weightSet">Phases are released</Typography>
                                            <ContentReleaseStatus>
                                                {this.state.allCourseLibraryData?.data?.attributes?.content_release_schedule_value}</ContentReleaseStatus>
                                        </AfterCountLesson>
                                        </SecondBox>
                                        <Box></Box>
                                    </BoxDataAlign>
                                    </>
                                    }
                                    <StyledAccordian style={styles.course_detail_phase}>
                                        {this.state.allCourseLibraryData.data?.attributes?.content_release_schedule ?
                                            <>
                                                {this.state.allCourseLibraryData.data?.attributes?.phases && Object.entries(this.state.allCourseLibraryData.data?.attributes?.phases).map(([k, v]: any) => {
                                                    return (
                                                        <Accordion style={styles.course_accordion}>
                                                            <AccordionSummary
                                                                expandIcon={<ExpandMoreIcon style={{color:"black"}} />}
                                                                aria-controls="panel1a-content"
                                                                id="panel1a-header"
                                                            >
                                                                <p style={styles.course_p}>{v.phase}</p>
                                                            </AccordionSummary>
                                                            <AccordionDetails style={styles.course_detail}>
                                                                {v?.videos?.map((ele: any, index: any) => {

                                                                    return (
                                                                        <Box key={index}>
                                                                            <MediaControlCard isFreeTrial={k} button={this.state.allCourseLibraryData?.data?.attributes?.button} condition={this.state.allCourseLibraryData?.data?.attributes?.is_enrolled} ButtonStart={this.startButton} index={index} ele={ele} />
                                                                        </Box>
                                                                    )
                                                                })}
                                                            </AccordionDetails>
                                                        </Accordion>
                                                    )
                                                })}
                                            </>
                                            :
                                            <>
                                                {this.state.allCourseLibraryData.data?.attributes?.videos.map((ele: any, index: any) => {
                                                    return (
                                                        <Box key={index} style={{ width: "100%" }}>
                                                            <MediaControlCard button={this.state.allCourseLibraryData?.data?.attributes?.button} condition={this.state.allCourseLibraryData?.data?.attributes?.is_enrolled} ButtonStart={this.startButton} index={index} ele={ele} />
                                                        </Box>
                                                    )
                                                })}
                                            </>
                                        }
                                    </StyledAccordian>
                                </Box>
                            </Paper>
                        </Grid>
                    </Grid>

                </Grid>
            </Grid>
            // Customizable Area End
        );
    }
}

// Customizable Area Start




export function MediaControlCard({ ele, ButtonStart, condition, index, button, isFreeTrial }: any) {
    const classes = useStyles();
    function showIcons(element: string) {
        switch (element) {
            case "video":
                return iconVideo
            case "document":
                return iconPage
            case "audio":
                return iconMusic
            case "image":
                return iconImage
            case "quiz":
                return iconQuiz
            default:
                return;
        }
    };

    return (
        <Card className={classes.root} style={styles.cardMedia}>
            <Box style={styles.media_mainBox} >
                <SequenceNumberShow> {ele.sequence_number}
                </SequenceNumberShow>
                <Box style={styles.media_boxtitle}>
                    {button === "start" ?
                        <h5 onClick={() => ButtonStart(ele)}
                            id="start-course-id-tst3"
                            style={styles.media_title}>
                            {ele?.title}
                        </h5>
                        :
                        <>
                            {isFreeTrial === "free_trial" ?
                                <h5 onClick={() => ButtonStart(ele)}
                                    style={styles.media_title}>
                                    {ele?.title}
                                </h5>
                                :
                                <Box>
                                    <NameWithCount
                                        style={styles.media_title}
                                        className="media_title">
                                       <span>{ele.title}</span> 
                                    </NameWithCount>
                                </Box>
                               
                            }
                        </>
                    }
                </Box>
                <Box>
                </Box>
            </Box>
            <IconArrange>
                <Box style={{ marginTop: "10px" }}>
                    {
                        <img src={showIcons(ele.icon_type)}/>
                    }
                </Box>
            </IconArrange>
        </Card>
    )
}




const styles = {
    cardMedia: {
        padding: "10px",
        display: "flex",
        justifyContent: "space-between",
        alignContent: "center",
        alignItem: "center",
    },
    media_mainBox: {
        position: 'relative' as 'relative',
        display:"flex",
    },
    media_img: {
        height: '65px',
        width: '106px',
        borderRadius: '9px'
    },
    media_boxplay: {
        position: 'absolute' as 'absolute',
        top: '50%',
        left: '50%',
        transform: 'translate(-50%,-50%)'
    },
    media_boxtitle: {
        marginLeft: '10px',
        marginTop: "10px"
    },
    media_title: {
        width: '100%', margin: 'auto',
        marginLeft: '0px', fontSize: '16px',
        fontWeight: 400, lineHeight: '16px',
        marginBottom: '10px', cursor: 'pointer',
    },
    media_chapter: {
        margin: 'auto',
        fontSize: '14px',
        lineHeight: '18px',
        fontWeight: 400,
        width: 'auto',
    },
    course_box: {
        height: '500px',
        maxWidth: '83vw',
        overflow: 'hidden',
        flex: 1
    },
    course_h3: {
        fontFamily: 'Montserrat'
    },
    course_detail: {
        flexDirection: 'column' as 'column',
        padding: 0,
        alignItems: 'center',
        display: 'block'
    },
    course_p: {
        fontWeight: 700,
        fontSize: "16px",
        margin: 0,
        textTransform: 'capitalize' as 'capitalize'
    },
    course_accordion: {
        boxShadow: 'none'
    },
    phase_h2: {
        marginTop: '-5px',
        fontFamily: 'Montserrat'
    },
    course_detail_phase: {
        flexDirection: 'column' as 'column',
        padding: 0,
        maxWidth: "unset",     
    },
    main_Accordion: {
        boxShadow: 'none',
        position: 'inherit' as 'inherit',       
        maxWidth: "unset !important",       
    },
    mainh2_sidebar: {
        marginTop: '-5px',
        fontFamily: 'Montserrat'
    },
    sidebar_paper: {
        marginBottom: '2rem',
        padding: '30px',
        borderRadius: '24px'
    },
    data_detail: {
        color: 'gray'
    },
    "& .tabBox": {
        marginTop: "15px",
        width: "100%",
      },
      "& .tabMainBox": {
        borderBottom: "1px solid rgba(234, 236, 240, 1)",
        minHeight: "0px",
      },
    data_detailP: {
        fontSize: "20px",
        fontStyle: 'roman' as 'roman',
        fontWeight: 600,
        fontFamily: 'Montserrat' as 'Montserrat'
    },
    data_detail_main: {
        color: 'rgba(80, 80, 80, 1)',
        fontFamily: 'Montserrat',
        fontWeight: 400,
        fontSize: "16px"
    },
    course_start: {
        background: '#2b2b2b',
        color: '#fff',
        borderRadius: '17px',
        padding: '6px 18px',
        cursor: 'pointer',
        marginLeft: '-10px'
    },
    course_buy: {
        background: '#2b2b2b',
        color: '#fff',
        borderRadius: '17px',
        padding: '6px 18px',
        cursor: 'pointer',
        marginLeft: '-10px'
    },
    start_button: {
        background: '#2b2b2b',
        color: '#fff',
        width: '160px',
        height: '50px',
        borderRadius: '24px',
        padding: '6px 18px',
        cursor: 'pointer',
        marginLeft: '-10px'
    },
    ratting: {
        marginTop: '3px',
        color: '#f9b651',
        fontSize: '16px'
    },
    rattingBox: {
        display: 'flex',
    },
    rattingTypo: {
        paddingTop:"15px",
        fontSize: '12px',
        fontWeight: 400
    },
    rattingGrid: {
        marginTop: '-15px',
        height: '15px'
    },
    cateTypo: {
        fontFamily: 'Montserrat' as 'Montserrat',
        paddingBottom: "10px",
        fontSize: '12px',
        fontWeight: 400
    },
    box_cate: {
        marginTop: '-12px',
        fontWeight: 400,
        fontSize: "16px",
        color: "#787776"

    },
    boxCreate: {
        marginTop: '-9px',
        fontWeight: 500,
        fontSize: "16px",
        color: "787776"
    },
    create_typo: {
        fontFamily: 'Montserrat' as 'Montserrat',
        paddingBottom: "10px",
        fontSize: '12px',
        fontWeight: 400
    },
    mainGridButton: {
    },
    main_title: {
        fontSize: "20px",
        fontStyle: 'roman' as 'roman',
        fontWeight: 'bold' as 'bold',
        fontFamily: 'Montserrat' as 'Montserrat'
    },
    main_titleBox: {
        display: 'flex',
        flexDirection: 'column' as 'column'
    },
    main_courseImg: {
        width: '100%',
        height: '100%',
        borderTopRightRadius: "20px",
        borderTopLeftRadius: "20px"
    },
    mainImg_box: {
        height: "343.95px",
        borderTopRightRadius: "10px",
        borderTopLeftRadius: "10px",
    },
    mainGridBox: {
        marginTop: '15px'
    },
    backdrop: {
        zIndex: theme.zIndex.drawer + 1,
        color: '#fff'
    },
    profileHeader: {
        fontFamily: 'Poppins',
        fontSize: '48px',
        fontWeight: 700
    },
    profileHeaderGrid: {
        paddingTop: '0px',
    }
}

const BoxDataAlign = styled(Box)({
    display: "flex",
        flexWrap: "wrap",
    justifyContent: "space-between",
    alignContent: "center",
    gap: "12px",
    alignItems: "center",
    padding: "20px 0px 20px 10px"
});

const SecondBox = styled(Box)({
    display: "flex",
    gap: "10px"
});

const StyledAccordian = styled(AccordionDetails)({

    "& .Mui-expanded":{
        maxWidth: "unset !important",
    },

    "&.MuiAccordionDetails-root":{
        maxWidth: "unset !important",
        border: "none",
        boxShadow: "none"
        
    },
});

const IconArrange = styled(Box)({
    display: "flex",
    justifyContent: "center",
    alignContent: "center",
    alignItem: "center"
});

const ImageSize = styled("span")({
    fontSize: "16px"
});

const CourseSetting = styled(Typography)({
    fontSize: "18px !important",
    fontWeight: 600
});

const CourseSettingAfter = styled(Typography)({
    fontSize: "12px !important",
    fontWeight: 700 
});

const LessonCountWithText = styled(Typography)({
    display: "flex", 
    flexDirection: "column", 
    fontSize: "16px", 
    justifyContent: "center", 
    alignContent: "center", 
    alignItems: "center",
    "& .MuiTypography-body1":{
        fontSize: "none"
    }
});

const BoxAligne = styled(Box)({
    width: "364px",
    height: "37px",
    display: "flex",
    justifyContent: "space-between"
});

const Divider = styled(Typography)({
    width: "2px", 
    height: "67px", 
    border: "1px Solid #E5E2E1" 
});


const LessonsFont = styled(Typography)({
    fontWeight: 400,
    fontSize: "12px !important"
});

const InnerBoxAligne = styled(Box)({
    width: "174px",
    height: "37px",
    display: "flex",
    justifyContent: "center",
    alignItem: "center",
    alignContent: "center",
    fontWeight: 600,
    fontSize: "16px"
});

const AfterCountLesson = styled(Box)({
    display: "flex", 
    flexDirection: "column", 
    justifyContent: "center", 
    alignContent: "center", 
    alignItems: "center",
     width: "162px", 
     height: "64px", 
     gap: "10px",
     "& .MuiTypography-body1":
     {
        fontWeight: 399,
     },
     "& .weightSet":{
        fontWeight: 400,
        fontSize: "14px"
     },
});

const ImageWithData = styled(Box)({
    display: "flex", 
    fontSize: "16px", 
    width: "111px", 
    height: "44px", 
    gap: "10px",
    "& .demo": {
        fontSize: "14px",
        fontWeight: 400
    },
});

const ContentReleaseStatus = styled(Typography)({
    background: "#E0D4E7",
    padding: "6px 9px 6px 9px",
    borderRadius: "88px",
    width: "86px",
    height: "40px",
    textAlign: "center",
    fontWeight: 400,
    fontSize: "14px !important",
    color: "#652786"
});

const StyledTabs = styled(Box)({
    display: "flex",
    position: "relative",
    width: "100%",
    "&::before":{
        content: "' '",
        height:"2px",
        width: "100%",
        backgroundColor: "lightgray",
        position: "absolute",
        bottom: "0",
        left:"0"
    }
});

const StyledTab = styled(Box)({
    color: "#1C1B1B",
    height: "81px",
    textAlign: "center",
    lineHeight: "80px",
    position: "relative",
    flex: "0.5",
    fontSize:"16px",
    fontWeight: 600,
    "&.activeTab":{
        color: "#652786",
    },
    "&.activeTab::before":{
        content: "' '",
        height:"4px",
        width: "100%",
        backgroundColor: "#652786",
        position: "absolute",
        bottom: "-1px",
        left:"0",
        borderRadius: "10px"
    }
});

const StyledProgressBar = styled(LinearProgress)({
        height: "8px !important",
        borderRadius: "5px",
        backgroundColor: "#EEE8F2 !important",
        "& .MuiLinearProgress-barColorPrimary":{
            backgroundColor: "#652786"
        }
      
});

const ProgressContainer = styled(Box)({
    display: "flex",
    flexDirection: "column",
    gap: "8px",
    margin: "20px 0",
    paddingBottom:"35px",
    borderBottom: " 1px solid #E5E2E1"
});

const ProgressTop = styled(Box)({
    display: "flex",
    justifyContent: "space-between",
});

const ProgressBottom = styled(Box)({
    display: "flex",
    justifyContent: "space-between",
});

const SequenceNumberShow = styled(Box)({
    height: '48px',
    width: '48px',
    borderRadius: '4px',
    color:"#652786",
    textAlign: "center",
    fontSize: "20px",
    fontWeight: 600,
    padding: "7px",
    border: "1px solid #E5E2E1 !important"
});

const NameWithCount = styled("p")({
    display: "flex",
    flexDirection: "column"
});

// Customizable Area End