import React from 'react'
import { Dialog, DialogTitle, DialogContent, DialogActions, Button, makeStyles, OutlinedInput } from '@material-ui/core';
import { closeIcon } from "./assets"

const useStyles = makeStyles((theme)=>({
    dialogWrapper:{
        "& [role='dialog']":{
            margin: '0',
            borderRadius: '12px',
            maxWidth: '525px',
            width:"100%",
            position:"relative",
            overflow:"visible",
            [theme.breakpoints.down('sm')]: {
                maxWidth:"385px"
          },
        },
      
      
    },
    close_icon:{
        position:"absolute",
        top:"-8px",
        right:"-8px",
        cursor:"pointer"
      },
    headingStyle: {
        color: "#000",
        fontSize: "22px",
        fontFamily: "Inter",
        fontStyle: "normal",
        lineHeight: "32px",
        fontWeight: 600,
        letterSpacing: "-0.12px",
        [theme.breakpoints.down('sm')]: {
          fontSize: "18px",
    },
    },
  
    btnWrapper: {
      display: "flex",
      justifyContent: 'space-between',
      padding: "24px 32px",
      borderTop: "0px",
      [theme.breakpoints.down('sm')]: {
          flexDirection: "column",
          gap: "10px",
      },
      '& button': {
          fontSize: '16px',
          fontWeight: 600,
          width: '214px',
          borderRadius: '12px',
          height: "42px",
          [theme.breakpoints.down('sm')]: {
              width: '100%',
          },
      }
  },

    primaryBtnStyle: {
      background: "#652786",
      marginLeft:"0px !important",
      '&:hover': {
        backgroundColor: '#652786',
    },
      "text-transform": "none",
      "color": "white",
     
    },
    cancelBtn: {
      border:"1px solid #E47004",
      color: '#E47004',
      background: "#fff",
      width:"214px !important",
      height:"42px",
      display: "flex",
      justifyContent: "center",
      alignItems: "center",
      gap: "10px",
      textTransform: "capitalize",
      borderRadius:"12px",
      [theme.breakpoints.down('sm')]: {
        width: '100% !important',
   },
  },
  
    textInputStyle: {
        margin: "0 !important",
        "& .MuiOutlinedInput-input": {
          textTransform:"uppercase",
        },
        "& .css-yk16xz-control":{
          color: "#000",
fontFamily: "Inter",
fontSize: "16px",
fontStyle: "normal",
fontWeight: 600,
lineHeight: "24px", 
          height:"56px"
        },
        "& .css-1pahdxg-control":{
          height:"56px",
          color: "#000",
fontFamily: "Inter",
fontSize: "16px",
fontStyle: "normal",
fontWeight: 600,
lineHeight: "24px", 
        },
        "&:hover .css-1pahdxg-control":{
          height:"56px",
          borderColor:"#2d6f8f"
        }
    },
    labelStyle:{fontSize:"14px",fontWeight:700,lineHeight:"22px",color:"#334155"},
    dialogTitle: {
        textAlign: "left",
        fontSize: "16px",
        fontWeight: 400,
        display: 'flex',
        justifyContent: 'space-between',
        alignItems: 'center',
        padding: "32px 16px 0px 32px",
    },
    subHeading: {
        fontSize: "16px",
        padding:"0px 8px",
        margin: "0px 4px"
    },

    reasonsSection: {
        display:"flex",
        flexDirection:"column",
        gap:"10px",
        width:"520px"
    },
    downloaIcon: {
        cursor: "pointer"
    }
}))

interface DeleteModalProps {
    openDialog:boolean,
    headingText: string,
    btnCancelText?: string,
    btnOkText: string,
    subHeading?: string;
    handleCancel:any;
    handleOk:any;
    library?:boolean;
}




const DeleteModal = (props:DeleteModalProps) => {
    const classes = useStyles();
      
  return (
    <div>
      <Dialog
        open={props?.openDialog}
        onClose={props?.handleCancel}
        aria-labelledby="alert-dialog-title"
        aria-describedby="alert-dialog-description"
        maxWidth="lg"
        disableBackdropClick={true}
        className={classes.dialogWrapper}
      >
        
        <DialogTitle id="alert-dialog-title" style={{padding:"0"}}>
           <div className={classes.dialogTitle}>
                <div>
                    <span className={classes.headingStyle}>{props?.headingText}</span>
                </div>
               
           </div>
        </DialogTitle>
       <DialogContent style={{ display:"flex",gap:"16px",color:"#000"}}>
          <span className={classes.subHeading}>{props.subHeading}</span>
       </DialogContent>
        
    <DialogActions className={classes.btnWrapper}>
                  <Button variant="outlined" className={classes.cancelBtn}
                        onClick={() => {
                            props.handleCancel()
                        }}
                    >
                        {props.btnCancelText}
                    </Button>
                   <Button variant="contained"  className={classes.primaryBtnStyle} onClick={() => {props.handleOk()}}>
                            {props.btnOkText}
                        </Button>        

        </DialogActions>
        <span className={classes.close_icon}>
          <img src={closeIcon} alt="cross_icon" onClick={()=>{props.library?props.handleOk():props.handleCancel()}}/>
        </span>
      </Dialog>
      
    </div>
  )
}

export default DeleteModal