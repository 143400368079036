// Customizable Area Start
import React from "react";
import './videostyles.css';
import MyContentController from "./MyContentController.web";
import { Box, Paper, Typography } from "@material-ui/core";
import CustomCarousel from "../../../../packages/components/src/CustomCarousel/CustomCarousel";
import { iconVideo } from "./assets";
import ArrowBackIosIcon from '@material-ui/icons/ArrowBackIos';
import DynamicTitle from "../../../components/src/DynamicTitle";
import ChevronRightIcon from '@material-ui/icons/ChevronRight';

export default class MyContentWeb extends MyContentController {
    renderContentData = (itemsToShow:number) => {
        return (
    <>
                    {this.state.inProgressData.map((conent: any, index: number) => {
                      const completedVideoCount = conent.attributes.completed_video_count || 0;
                      const totalVideoCount = conent.attributes.total_video_count || 0;
                      const completionPercentage = totalVideoCount ? (completedVideoCount / totalVideoCount) * 100 : 0;
                      return (
                    <Box style={{
                      margin:"0 15px" ,height: "407px",
                      position: "relative",width:"270px",
                    }} key={index}>
                      <Box
                        style={{
                          height: "181px", padding: "16px 0px 0px 0px", gap: "13px",
                          borderRadius: "12px 0px 0px 0px",opacity: "0px",
                        }}>
                        <img width="100%" height="100%" style={{borderRadius:"12px"}} 
                        src={conent.attributes.collection.attributes.thumbnail_image.url} alt="kfjakhj" />
                      </Box>
    
                      <Box style={{
                          width:"100%",height: "242px",
                          padding: "24px",borderRadius: "12px ",
                          position: "absolute",bottom:5,
                          backgroundColor:"white",boxShadow: "1px 2px 10px 0px rgba(0, 0, 0, 0.07)"
                        }}>
                          <Box style={{ display:"flex",
                            gap: "16px",flexDirection:"column"
                          }}>
    
                         
                        <Typography
                          style={{
                            fontFamily: "Poppins",fontSize: "16px",
                            fontWeight: 700,lineHeight: "22px",
                            textAlign: "left",color:"#1C1B1B"
                          }}>{conent.attributes.collection.attributes.title}</Typography>
    
                        <Typography style={{
                            fontFamily: "Poppins",fontSize: "14px",
                            fontWeight: 500, lineHeight: "22px",
                          }}
                        >{conent.attributes.collection.attributes.created_by}</Typography>
    
                        <Typography
                          style={{
                            fontFamily: "Poppins",
                            fontSize: "12px",fontWeight: 400,
                            lineHeight: "18px",color:"#484646"
                          }}
                        >{`Due in ${conent.attributes.due_in_days} Days`}</Typography>
    
                        <Box style={{display:"flex",justifyContent:"space-between"}}><Box>
                          <Box style={{ 
        width: "154px", height: "10px", borderRadius: "88px", 
        backgroundColor: "rgba(236, 195, 195, 0.23)", position: "relative" 
      }}>
        <span style={{ 
          display: "block",  width: `${completionPercentage}%`, 
          height: "10px",  borderRadius: "88px", 
          background: "linear-gradient(155.66deg, #EF2B4B -1.02%, #FF8B3F 68.24%)",position: "absolute", 
          top: 0, left: 0 
        }}></span>
      </Box>
                            <Typography style={{
                              color: "var(--sys-color-Secondary, rgba(228, 112, 4, 1))",
                              fontWeight:400,fontSize:"12px",
                              marginTop:"10px"
                            }}>
                          {`${conent.attributes.completed_video_count}/${conent.attributes.total_video_count} Lessons Completed`}</Typography>
                          </Box><Box style={{
                              width:"40px",height:"40px",borderRadius:"8px",boxShadow: "1px 2px 10px 0px rgba(0, 0, 0, 0.07)",
                              display:"flex",justifyContent:"center",alignItems:"center" }}>
                            <img src={iconVideo}/>
                            </Box>
                         </Box>
                      </Box>
                      </Box>
    
    
    
                    </Box>
                  )})}</>
        )
      }

    renderInProgress = () => {
        return (
          <Box style={{gap:"16px",margin:"10px 0px 10px 20px"}}>
          <Paper className="paper" style={{ display: "flex",justifyContent:"space-between",alignItems:"center", }}>
            <Typography style={{...styles.typography_personal,marginLeft:"5px"}}>In progress</Typography>
            <Typography style={{...styles.flexBetween,color:"#652786"}}
            id="navigate-personal-tst-btn">View More <ChevronRightIcon />
            </Typography>
              </Paper>
              <Box className="cutomCarouselBox">
            <CustomCarousel data-test-id="contentDataCallId" children={this.renderContentData} datavalue={this.state.inProgressData} getTransformValue={this.getTransformValue}/>
            </Box>
        </Box>
        )
      }
    render() {
        return (
            <Box>
                <div style={{ display: "flex", alignItems: "center", justifyContent: "start" }}>
                <ArrowBackIosIcon
                data-test-id="gotoBackBtn"
                onClick={this.gotoBackSide}
                style={{ marginLeft: "8px", cursor: "pointer", color: "#92929D" }} />
              <DynamicTitle title="My content" />
            </div>
                     {this.state.inProgressData.length > 0 && this.renderInProgress()}
            </Box>
        )
    }
}

const styles = {
    img_shape: {
        paddingLeft: '13px'
      },
      typography_personal: {
        fontWeight: 700,
        fontSize: '24px'
      },
      flexBetween:{
        display:"flex",
        justifyContent:"space-between",
        alignItems:"center"
      }
}
// Customizable Area End
