
import { IBlock } from "../../../framework/src/IBlock";
import { Message } from "../../../framework/src/Message";
import { BlockComponent } from "../../../framework/src/BlockComponent";
import MessageEnum, {
  getName,
} from "../../../framework/src/Messages/MessageEnum";
import { runEngine } from "../../../framework/src/RunEngine";

// Customizable Area Start
import { imgPasswordInVisible, imgPasswordVisible, slider1, slider2, slider3 } from "./assets";
import { toast } from 'react-toastify';
// import 'react-toastify/dist/ReactToastify.css';
// Customizable Area End

export const configJSON = require("./config");

export interface Props {
  navigation: any;
  id: string;
  history?: any;
  location?: any;
  // Customizable Area Start
  handleCartItemsCountInTopbar?: (count: number) => void;
  // Customizable Area End
}

interface S {
  txtInputValue: string;
  txtSavedValue: string;
  enableField: boolean;
  // Customizable Area Start
  anchorEl: any;
  currRating: any;
  grapValues: any;
  allCourseLibraryData: any;
  open: any;
  priceFree: any;
  style: boolean,
  cartItemsCount: number;
  activeTab: number;
  // Customizable Area End
}

interface SS {
  id: any;
  // Customizable Area Start
  // Customizable Area End
}

export default class CourseController extends BlockComponent<
  Props,
  S,
  SS
> {
  // Customizable Area Start
  GetCourseLibraryData: any
  PostCourseLibraryData: any
  param_id: any
  param_id_title: any;
  AddCartItemID: any;
  getCartItemsApiCallId: string | Message = "";
  // Customizable Area End

  constructor(props: Props) {
    super(props);
    this.receive = this.receive.bind(this);

    // Customizable Area Start
    this.subScribedMessages = [
      getName(MessageEnum.AccoutLoginSuccess),
      // Customizable Area Start
      getName(MessageEnum.RestAPIResponceMessage),
      // Customizable Area End
    ];

    this.state = {
      txtInputValue: "",
      txtSavedValue: "A",
      enableField: false,
      // Customizable Area Start
      anchorEl: '',
      grapValues: 'Week',
      currRating: '',
      allCourseLibraryData: {},
      priceFree: false,
      open: false,
      style: true,
      cartItemsCount: 0,
      activeTab:0
      // Customizable Area End
    };
    runEngine.attachBuildingBlock(this as IBlock, this.subScribedMessages);
    this.param_id = this.props.navigation.getParam("id");
    this.param_id_title = this.props.navigation.getParam("title");

    // Customizable Area Start
    // Customizable Area End
  }

  async componentDidMount() {
    this.getCourseLibraryData(this.param_id);
    this.getCartItems();
    window.scrollTo(0, 0)
  }

  componentDidUpdate(prevProps: Props) {
    if (this.props.location.pathname !== prevProps.location.pathname) {
      this.getCourseLibraryData();
    }
  }

  async receive(from: string, message: Message) {
    const messageId = message.id;
    const restApiResponceMessage = getName(MessageEnum.RestAPIResponceMessage);
    const restApiResponceDataMessage = getName(MessageEnum.RestAPIResponceDataMessage);
    const restApiResponceSuccessMessage = getName(MessageEnum.RestAPIResponceSuccessMessage);

    if (messageId === getName(MessageEnum.AccoutLoginSuccess)) {
      this.accountLoginSuccessHandle(message);
    }

    // Customizable Area Start
    if (messageId === restApiResponceMessage) {
      const apiRequestCallId = message.getData(restApiResponceDataMessage);
      const responseJson = message.getData(restApiResponceSuccessMessage);

      if (apiRequestCallId && responseJson) {
        switch (apiRequestCallId) {
          case this.GetCourseLibraryData:
            this.setState({
              allCourseLibraryData: responseJson,
              currRating: responseJson?.data?.attributes?.average_rating,
              open: false,
              priceFree: this.state.allCourseLibraryData?.data?.attributes?.button === "cart"
            });
            break;

          case this.PostCourseLibraryData:
            this.getCourseLibraryData();
            if (this.state.allCourseLibraryData?.data?.attributes?.button === "enroll") {
              toast('Course Add in Personal');
            }
            break;

          case this.AddCartItemID:
            this.handleAddCartItemResponse(responseJson);
            break;

          case this.getCartItemsApiCallId:
            this.handleGetCartItemsApiResponse(responseJson);
            break;

          default:
            break;
        }
      }
    }
    // Customizable Area End
  }


  txtInputWebProps = {
    onChangeText: (text: string) => {
      this.setState({ txtInputValue: text });
    },
    secureTextEntry: false,
  };

  txtInputMobileProps = {
    ...this.txtInputWebProps,
    autoCompleteType: "email",
    keyboardType: "email-address",
  };

  txtInputProps = this.isPlatformWeb()
    ? this.txtInputWebProps
    : this.txtInputMobileProps;

  btnShowHideProps = {
    onPress: () => {
      this.setState({ enableField: !this.state.enableField });
      this.txtInputProps.secureTextEntry = !this.state.enableField;
      this.btnShowHideImageProps.source = this.txtInputProps.secureTextEntry
        ? imgPasswordVisible
        : imgPasswordInVisible;
    },
  };

  btnShowHideImageProps = {
    source: this.txtInputProps.secureTextEntry
      ? imgPasswordVisible
      : imgPasswordInVisible,
  };

  btnExampleProps = {
    onPress: () => this.doButtonPressed(),
  };

  doButtonPressed() {
    let msg = new Message(getName(MessageEnum.AccoutLoginSuccess));
    msg.addData(
      getName(MessageEnum.AuthTokenDataMessage),
      this.state.txtInputValue
    );
    this.send(msg);
  }

  // web events
  setInputValue = (text: string) => {
    this.setState({ txtInputValue: text });
  };

  setEnableField = () => {
    this.setState({ enableField: !this.state.enableField });
  };

  // Customizable Area Start
  accountLoginSuccessHandle = (message: Message) => {
    let value = message.getData(getName(MessageEnum.AuthTokenDataMessage));

    this.showAlert(
      "Change Value",
      "From: " + this.state.txtSavedValue + " To: " + value
    );

    this.setState({ txtSavedValue: value });
  }
  handleClick = (event: React.MouseEvent<HTMLButtonElement>) => {
    this.setState({ anchorEl: event.currentTarget });
  };

  handleClose = () => {
    this.setState({ anchorEl: null });
  };

  startPlay = (id: any) => {
    this.props.history.push(`/title/${id?.attributes?.course_video_track?.last_video_id}${id.attributes?.title.replace(/\s/g, '-')}`, { program: this.props.history.location.state.program })
  }

  startButton = (ele: any) => {
    this.props.history.push(`/title/${ele?.id}${this.state.allCourseLibraryData?.data?.attributes?.title.replace(/\s/g, '-')}`, { program: this.props.history.location.state.program })
  }

  handleSubscription = (data: any) => {
    this.props.history.push('/Subscription', { name: data, type: 'Course' })
  }

  getCourseLibraryData = async (id?: any) => {

    this.setState({ open: true });
    const header = {
      "Content-Type": "application/json",
      token: localStorage.getItem("token")
    };
    const requestMessage = new Message(
      getName(MessageEnum.RestAPIRequestMessage)
    );
    this.GetCourseLibraryData = requestMessage.messageId;

    const p_id = id || this.props.navigation.getParam('id');
    const p_title = this.props.navigation.getParam("title")
    const p_course = this.props.navigation.getParam("type")

    const endpoint = p_course === 'Program'
      ? `bx_block_playlist/user_courses?program_id=${this.props.history.location.state.program}&id=${p_id}&title=${p_title}`
      : `bx_block_playlist/user_courses?id=${p_id}&title=${p_title}`;

    requestMessage.addData(
      getName(MessageEnum.RestAPIResponceEndPointMessage),
      endpoint
    );
    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestHeaderMessage),
      header
    );
    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestMethodMessage),
      'get'
    );
    runEngine.sendMessage(requestMessage.id, requestMessage);
  }


  postXpandLibraryData = async (id: any) => {
    const header = {
      "Content-Type": "application/json",
      token: localStorage.getItem("token")
    };
    const httpBody = {
      "courseable_id": id,
      "courseable_type": "BxBlockCoursecreation::Course" // course: "BxBlockCoursecreation::Course, program: "BxBlockCoursecreation::Program"
    }
    const requestMessage = new Message(
      getName(MessageEnum.RestAPIRequestMessage)
    );
    this.PostCourseLibraryData = requestMessage.messageId;
    requestMessage.addData(
      getName(MessageEnum.RestAPIResponceEndPointMessage),
      `bx_block_playlist/enroll_courses`
    );
    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestHeaderMessage),
      header
    );
    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestMethodMessage),
      'Post'
    );
    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestBodyMessage),
      JSON.stringify(httpBody)
    );
    runEngine.sendMessage(requestMessage.id, requestMessage);

  }

  // Add item to cart
  addCartItem = async (id: any, type: string) => {
    const header = {
      "Content-Type": "application/json",
      token: localStorage.getItem("token"),
    };

    const payload = { course_id: id };

    const requestMessage = new Message(
      getName(MessageEnum.RestAPIRequestMessage)
    );

    this.AddCartItemID = requestMessage.messageId;

    requestMessage.addData(
      getName(MessageEnum.RestAPIResponceEndPointMessage),
      configJSON.addCartItemApiEndPoint
    );

    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestHeaderMessage),
      header
    );

    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestMethodMessage),
      "Post"
    );

    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestBodyMessage),
      JSON.stringify(payload)
    );

    runEngine.sendMessage(requestMessage.id, requestMessage);
  };

  // Handle add to cart response
  handleAddCartItemResponse = (response: any) => {
    if (response?.errors) {
      toast.error("Something went wrong");
      return;
    }

    if (response.message) {
      toast.warning('Item already in cart');
    } else {
      toast.success('Item has been added to your cart.')
      this.setState({
        cartItemsCount: this.state.cartItemsCount + 1
      }, () => {
        this.props?.handleCartItemsCountInTopbar &&
          this.props.handleCartItemsCountInTopbar(this.state.cartItemsCount);
      });
    }
  }

  // Get current cart items
  getCartItems = async () => {
    const header = {
      "Content-Type": "application/json",
      token: localStorage.getItem("token")
    };

    const requestMessage = new Message(
      getName(MessageEnum.RestAPIRequestMessage)
    );

    this.getCartItemsApiCallId = requestMessage.messageId;

    requestMessage.addData(
      getName(MessageEnum.RestAPIResponceEndPointMessage),
      configJSON.addCartItemApiEndPoint
    );

    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestHeaderMessage),
      header
    );

    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestMethodMessage),
      configJSON.getApiMethod
    );

    runEngine.sendMessage(requestMessage.id, requestMessage);
  }

  // Handle get cart items api response
  handleGetCartItemsApiResponse = (response: any) => {
    if (response?.error) {
      console.error("ERROR FETCHING CART ITEMS");
    } else if (response?.message) {
      this.setState({
        cartItemsCount: 0,
      }, () => {
        this.props?.handleCartItemsCountInTopbar &&
          this.props.handleCartItemsCountInTopbar(this.state.cartItemsCount);
      });
    } else if (response?.data) {
      const oneTimePaymentCartItems = response.data.attributes.cart_item;
      const subscriptionCartItems = response.data.attributes.subscription_cart_item;
      const totalCartItemsCount = oneTimePaymentCartItems.length + subscriptionCartItems.length;
      this.setState({
        cartItemsCount: totalCartItemsCount,
      }, () => {
        this.props?.handleCartItemsCountInTopbar &&
          this.props.handleCartItemsCountInTopbar(this.state.cartItemsCount);
      });
    }
  };



  // Customizable Area End
}
