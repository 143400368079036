import React from "react";
import { Box, Typography, styled, Button, Modal, TextField } from "@material-ui/core";
import { DragDropContext, Droppable, Draggable } from 'react-beautiful-dnd';
import { NotFoundImage, addIconHover, audioIcon, collapseIcon, deleteIcon, documentImage, dotsIcon, dropDownArrow, editIcon, iconClose, iconPage, iconQuiz, lessonDelete, lessonImage, videoIcon } from "./assets";
import { ErrorOutlineOutlined } from "@material-ui/icons";


type PhaseDragDropProps = {
  onDragEnd:any;
  phasesContent:any;
  handleMouseEnter:any;
  handleMouseLeave:any;
  handleLessonDeleteDialog:any;
  addDialogBoxopen:boolean;
  dialogImageToggle:boolean
  handleAddIconClick:any;
  handleLessonOpen: any;
  hoverLessonId:any
  isHovering:string
  handleDragEnter:any
  handleDragLeave:any
  showPhassesDropDown:any
  editingIndex:any
  courseData:any;
  handlePhaseMouseEnter:any
  hoverPhaseId:any;
  handlePhaseDeleteDialog:any
  handlePhaseAddIconClick:any
  handleAddIconOpenLessonNew:(previousLessonId?:string, phaseId?:string)=>void;
  handleMouseLeavePhase:any
  showPhasesDialogLesson:any
  handleMouseEnterPhases:any
  handleRenameText:any
  handleLeavePhaseRename:any
  editingText:string
  showRenameDialog:any
  handleDropDown:any
  handleEnterPhaseRename:any
  handleEditClick:any
  handleSaveName:any
  webStyle:any,
  handleOpenLessson:any,
  showEmptyText:any
}



const PhaseDragDrop = (props:PhaseDragDropProps) => {
    const icons:any = {
      video: videoIcon,
      audio: audioIcon,
      document: documentImage
    };
    return (
      <DragDropContext onDragEnd={props.onDragEnd}>
        <Droppable droppableId="phases" type="phase">
          {(provided) => (
            <div {...provided.droppableProps} ref={provided.innerRef}>
              {props.phasesContent.map((phase:any, index:any) => {
                  const isNotFirst = index > 0;
                  const isNotLast = index < props.phasesContent.length - 1;
                  const previousContentId = props.phasesContent[index].id;
                  const nameIsEmpty = !phase.attributes.name;
                  return(
                <Draggable key={phase.id} draggableId={phase.id.toString()} index={index}>
                  {(provided) => (
                    <div ref={provided.innerRef} {...provided.draggableProps}>
            
            <div style={{ position: 'relative' }} onMouseEnter={() => props.handleDragEnter(index)} onMouseLeave={props.handleDragLeave}> {/* Ensure relative positioning for accurate absolute positioning */}
                    <div
                          style={{
                            position: 'absolute',
                            left: '-22px',
                            top: '50%',
                            transform: 'translateY(-50%)',
                            cursor: 'pointer',
                            opacity: props.isHovering === index ? '1':'0', 
                            transition: 'opacity 0.2s',
                          }}
                          {...provided.dragHandleProps}
                        >
                          <img
                            src={dotsIcon}
                            style={{
                              width: '24px',
                              height: '24px',
                            }}
                            alt="Organize"
                          />
                        </div>

            <Box data-test-id={`hoverPhaseDiv${index}`} style={{...props.webStyle.mainLessonWrapper,position: 'relative'}}  onMouseEnter={props.handlePhaseMouseEnter.bind(this, index)}>
            <Box style={props.webStyle.lessonRightContainer}>
           
              {
                props.showPhassesDropDown !== index ? (
                  <img data-testid="dropDownClose" onClick={props.handleDropDown.bind(this, index)} src={collapseIcon} alt="Arrow" style={props.webStyle.dropDownArrow} />
                ) : (
                  <img src={dropDownArrow} alt="Arrow" style={props.webStyle.dropDownArrow} data-testid="dropDownOpen" onClick={props.handleDropDown.bind(this, "")} />
                )
              }
              <img src={lessonImage} alt="lessonImage" style={props.webStyle.dropDownArrow} />
              <div style={{display:"flex",flexDirection:"column"}}>
              <Typography style={props.webStyle.modalSubHeading} onMouseLeave={props.handleLeavePhaseRename}>
                    Phase {index+1} - 
                    {props.editingIndex === index ? (
                      <>
                      <TextField 
                        name='title'
                        placeholder="Enter a name"
                        className="textField"
                        value={props.editingText}
                        type="text"
                        id="bootstrap-input"
                        data-test-id={`handleName${index}`}
                        onChange={props.handleRenameText}
                      />
                      <Button data-test-id={`saveBtn${index}`} style={{...props.webStyle.buttonText,textTransform:"capitalize"}} onClick={() => props.handleSaveName(phase.id,props.editingText)}>Save</Button>
                      </>
                    ) : (
                      <div style={{ position: "relative" }}>
                        <span
                        data-test-id={`hoverName${index}`}
                          style={{ color: nameIsEmpty ? 'grey': "" }}
                          onMouseEnter={props.handleEnterPhaseRename.bind(this, index)}
                        >
                          {nameIsEmpty ? "Add a name" : phase.attributes.name}
                        </span>
                        {props.showRenameDialog === index && (
                                  <Box style={props.webStyle.dialogRenamePhases}>
                                  <Box data-test-id={`renameTitle${index}`} style={props.webStyle.addQuizButtonPhases} onClick={() => props.handleEditClick(index, phase.attributes.name)}>
                                    <img src={editIcon} alt="Edit Icon" />
                                    <Typography style={props.webStyle.buttonText2}>
                                      Rename
                                    </Typography>
                                  </Box>
                                </Box>
                                  )}
                        
                      </div>
                    )}
                  </Typography> 
                  {props.showEmptyText(phase,index) && <Typography style={{display:"flex",alignItems:"center",fontSize:"12px",fontFamily:"Poppins",color:"grey"}}> <ErrorOutlineOutlined style={{marginRight:"8px",width:"16px",height:"16px"}}/> This phase is empty.</Typography>} 
                  </div>
            </Box>
            <Box sx ={ props.webStyle.buttonsLesson} data-testId={`mouseEnterPhases${index}`} onMouseEnter={props.handleMouseEnterPhases.bind(this,index)} 
            onMouseLeave={props.handleMouseLeavePhase}
            >
              <Button style={ props.webStyle.addQuizButton}>
                <img src={iconPage} style={{marginRight:"10px"}} alt="iconQuiz" />
                <Box>
                <Typography onClick={()=>props.handleOpenLessson(phase.id)} style={{ ...props.webStyle.buttonText, position: "relative" }}>Add Lesson</Typography>
                </Box>

              </Button>
              <img src={lessonDelete} data-testId={`deletePhase${index}`} onClick={()=>props.handlePhaseDeleteDialog(phase.id,index)} style={props.webStyle.dropDownArrow}  alt="lessonDelete"  />
            </Box>
                        </Box>
                        </div>
                        {props.hoverLessonId == "" &&props.hoverPhaseId !== "" && props.hoverPhaseId == index && (
            
                          <Box sx={props.webStyle.hoverContainer}>
                            <Button>                             
                                  <img style={{zIndex:10}} src={addIconHover} alt="addIconHover" data-testid={`hoverPhaseAdd${index}`} onClick={() => props.handlePhaseAddIconClick(props.courseData.id, isNotFirst || isNotLast ? previousContentId : "")} />
                            </Button>
                          </Box>
                        
            )
            }
            {props.showPhassesDropDown !== "" && props.showPhassesDropDown == index && (
              <Box style={props.webStyle.lessonContaintContainer} data-testId="mouseLeave"
                onMouseLeave={() => props.handleMouseLeave()}>

          {phase.attributes.content.length > 0 ? 
            (
              <Droppable droppableId={`content-${phase.id}`} type="content">
              {(contentProvided) => (
                <div {...contentProvided.droppableProps} ref={contentProvided.innerRef}>
                  {phase.attributes.content.map((item:any, index:any) => {
                      const icon = icons[item.type]  
                      const titlePrefix = `Lesson ${index+1}`;
                      const title = `${item.title}`;
                      const previousContentId=item.id;
                      const phaseId= phase.id;
                    return(
                    <Draggable key={item.id} draggableId={item.id.toString()} index={index}>
                      {(itemProvided) => (
                        <div
                          ref={itemProvided.innerRef}
                          {...itemProvided.draggableProps}

                        >
                          <Box data-testid={`hover_id${index}`} style={props.webStyle.innnerRowWrapper}
          onMouseEnter={props.handleMouseEnter.bind(this, index)}
        >
          <Box style={props.webStyle.innerContainer} >
            <Box style={props.webStyle.innerRightContainer} >
              <img {...itemProvided.dragHandleProps} src={dotsIcon} alt="dotsIcon" style={{ width: "20.55px", height: "20px" }} />
              <img src={icon} alt="dotsIcon" style={{ width: "24.66px", height: "24px" }} />
              <Typography style={props.webStyle.primaryText} >{titlePrefix} - <span style={props.webStyle.spanText} >{title}</span></Typography>
            </Box>
            <Box style={props.webStyle.innerLeftContainer} >
              <img src={editIcon} alt="editIcon" />
              <img src={deleteIcon} data-testid={`DeleteBtn${index}`} alt="deleteIcon" onClick={()=>props.handleLessonDeleteDialog(titlePrefix,item.id)} />
            </Box>
          </Box>
          {
            props.hoverLessonId !== "" && props.hoverLessonId == index && (
              <>
                <Box sx={props.webStyle.hoverContainer}>
                  <Button>
                    {
                      props.dialogImageToggle ? (
                        <img src={iconClose} alt="iconClose" data-testid="hoverDialogClose" onClick={() => props.handleAddIconClick(false,previousContentId, phaseId)} />
                      ) : (
                        <img style={{zIndex:10}} src={addIconHover} alt="addIconHover" data-testid="hoverDialogAdd" onClick={() => props.handleAddIconClick(true,previousContentId, phaseId)} />
                      )
                    }
                  </Button>
                </Box>
              </>
            )
          }
        </Box>
                        </div>
                      )}
                    </Draggable>
              )})}
                  {contentProvided.placeholder}
                </div>
              )}
            </Droppable>
            ):
            (
              <Box style={props.webStyle.secondContainerLesson}>
                <MainNotFoundBox>
                  <LessonMainInnnerBox>
                    <Box style={props.webStyle.innerRightContainer}>
                      <ImageComponent src={NotFoundImage} style={props.webStyle.imageContainerLesson} />
                    </Box>
                    <NotFoundTextLesson>Please add a lesson to this phase</NotFoundTextLesson>
                  </LessonMainInnnerBox>
                  <Box sx={props.webStyle.buttons}>
                    <Button style={{
                      ...props.webStyle.secondaryButton,
                      border: "1px solid #652786",
                      backgroundColor: "#652786"
                    }} onClick={()=>props.handleLessonOpen(phase.id)}>
                      <Typography style={{...props.webStyle.buttonText,color:"#fff"}}>
                        + Add Lesson
                      </Typography>
                    </Button>
                  </Box>
                </MainNotFoundBox>
              </Box>
            )
            }
            </Box>
            )
          }
                    </div>
                  )}
                </Draggable>
              )})}
              {provided.placeholder}
            </div>
          )}
        </Droppable>
      </DragDropContext>
    );



  }
  export default PhaseDragDrop
  
  const NotFoundTextLesson = styled(Typography)({
    fontFamily: "Poppins",
    fontWeight: 600,
    fontSize: "20px",
    lineHeight: "24px",
    color: "##000000",
    textAlign: "center",
    marginTop: "32px"
  });
  
  const LessonMainInnnerBox = styled(Box)({
    width: "450px",
    height: "250px",
  });
  
  const ImageComponent = styled("img")({
    width: "616.47px",
    height: "404px"
  });
  
  const MainNotFoundBox = styled(Box)({
    display: "flex",
    alignItems: "center",
    justifyContent: "center",
    width: "100%",
    flexDirection: "column",
    paddingBottom:"20px",
    paddingTop:"20px"
  });