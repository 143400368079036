import React from "react";
// Customizable Area Start
import {
  List,
  ListItem as MUIListItem,
} from "@material-ui/core";
import { createTheme, styled } from "@material-ui/core/styles";
import {NewFilter} from "./assets"
// Customizable Area End

const theme = createTheme({
  palette: {
    primary: {
      main: "#0000ff",
      contrastText: "#fff",
    },
  },
});
// Customizable Area End

import AdvancedSearchController, { Props } from "./AdvancedSearchController";

export default class AdvancedSearch extends AdvancedSearchController {
  constructor(props: Props) {
    super(props);
    // Customizable Area Start
    // Customizable Area End
  }

  render() {
    // Customizable Area Start
    return (
      <>
        <TextFieldComponent
          className={this.props.isFilterDropActive ?"border_line":"grey_border"}
          id="search"
          name="search"
          value={this.props.searchInputValue}
          placeholder="Search"
          onChange={this.props.handleInputChange}
          style={this.props.inputWidth ? {width:`${this.props.inputWidth}`} : {width: "480px"}}
        />
        <SearchIconBox className="span_tag">
        <img src={NewFilter}/>
        </SearchIconBox>
        {this.props.showList && this.props.filterList.length > 0 && (
          <ComponentUl data-test-id="list-filter-id">
            <List>
              {this.props.filterList.map((item, index) => (
                <ListItem
                  button
                  key={index}
                  onClick={() =>this.props.handleListItemClick &&  this.props.handleListItemClick(item)}
                >
                  {item}
                </ListItem>
              ))}
            </List>
          </ComponentUl>
        )}
      </>
    );
    // Customizable Area End
  }
}

// Customizable Area Start
const SearchIconBox = styled("span")({
  position:"absolute",
  color: "#ADAAAA",
  left:"20px",
  top:"32%",
  '@media (max-width: 600px)': {
    left:"0px",
  },
});

const TextFieldComponent = styled('input')({
  poistion:"relative",
    borderRadius:"8px",
    borderTopRightRadius: "0px !important",
    borderBottomRightRadius: "0px !important",
  
    height: '56px',
    borderRight: "none",
    transition: 'border-color 0.3s ease',
    backgroundColor: "#FCF6F6",
    padding:"16px 30px 16px 50px",
    fontSize: "16px",
     fontWeight: 400,
     fontFamily: "Poppins, sans-serif !important",
    color: "#000",
    "&::placeholder":{
      color: "#ADAAAA !important", 
    },  
    "&:focus":{
      border: "1px solid #652786",
      outline: "none",
      backgroundColor:"white"
    },
    "&:hover":{
      borderColor: '#652786 !important',
      borderBottomRightRadius: "0px !important",
      borderTopRightRadius: "0px !important",
     },
     "&.grey_border":{
      border: '1px solid #E5E2E1',
     },
     "&.border_line":{
      border:"1px solid #652786 !important"
    },
     
    '@media (max-width: 600px)': {
     marginLeft:"-20px",
   },
});

const ComponentUl = styled("div")({
  position: "absolute",
  top: "97%",
  left: "3px",
  width: "98%",
  boxShadow: "0px 2px 8px 0px rgba(0, 0, 0, 0.15)",
  maxHeight: "200px",
  overflowY: "auto",
  zIndex: 999,
  backgroundColor: "#fff",
  borderRadius: "12px",
  borderTopRightRadius: "0px",
  borderTopLeftRadius: "0px",
  maxWidth:"530px"
});

const ListItem = styled(MUIListItem)({
  cursor: "pointer",
  color: "#1C1B1B",
  fontSize: "16px",
  fontWeight: 400,
  fontFamily: "Poppins, sans-serif",
  padding: "10px 16px",
  backgroundColor: "transparent",
  "&:hover, &:focus": {
    backgroundColor: "#EEE8F2",
    color: "#652786",
  },
});

// Customizable Area End
