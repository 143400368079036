// Customizable Area Start
import { IBlock } from "../../../framework/src/IBlock";
import { Message } from "../../../framework/src/Message";
import { BlockComponent } from "../../../framework/src/BlockComponent";
import MessageEnum, {
  getName,
} from "../../../framework/src/Messages/MessageEnum";
import { runEngine } from "../../../framework/src/RunEngine";
import { sortTableData } from "../../utilities/src/TableHelpers";

import { imgPasswordInVisible, imgPasswordVisible } from "./assets";
export const configJSON = require("./config");

export interface Props {
  navigation: any;
  id: string;
}
export interface TeamList{
  id: string,
  type: string,
  attributes: {
    id:number,
    name: string,
    description: string,
    is_selected: boolean,
    manager:any,
    branches: any,
    users: any,
    users_count: number
  }
}

interface S {
  txtInputValue: string;
  txtSavedValue: string;
  enableField: boolean;
  anchorTeamFilter:boolean;
  AdvanceSearchValue:string;
  filteredContentData:any;
  showContentLists:boolean;
  placeholderSelectItems:string;
  TeamList:Array<TeamList>;
  TeamId:string;
  BranchModal:boolean;
  TeamName:string,
  Branches:any,
  branchSelect:number|null,
  branchName:string,
  DeletePopup:boolean,
  DeleteId:string,
  deletealert:{ isOpen: boolean, msg?: string, type?: string },
  CreateTeam:boolean
}

interface SS {
  id: any;
}

export default class TeamBuilderController extends BlockComponent<
  Props,
  S,
  SS
> {

  getTeamCollectionCallId:string="";
  getAdvanceSearchApiCallIds:string="";
  getDeleteApiCallIds:string="";
  
  constructor(props: Props) {
    super(props);
    this.receive = this.receive.bind(this);

    this.subScribedMessages = [
      getName(MessageEnum.AccoutLoginSuccess),
      getName(MessageEnum.RestAPIRequestMessage),
      getName(MessageEnum.RestAPIResponceMessage),
      getName(MessageEnum.NavigationMessage),
      getName(MessageEnum.NavigationPayLoadMessage),
      getName(MessageEnum.NavigationTargetMessage),
      getName(MessageEnum.NavigationPropsMessage)
    ];
    this.state = {
      txtInputValue: "",
      txtSavedValue: "A",
      enableField: false,
      anchorTeamFilter:false,
      AdvanceSearchValue:"",
      filteredContentData:[],
      showContentLists:false,
      placeholderSelectItems:"",
      TeamList:[],
      TeamId:"",
      BranchModal:false,
      TeamName:"",
      Branches:[],
      branchSelect:null,
      branchName:"",
      DeletePopup:false,
      DeleteId:"",
      deletealert:{ isOpen: false, msg: " ", type: "success" },
      CreateTeam:false
    };
    runEngine.attachBuildingBlock(this as IBlock, this.subScribedMessages);

  }

  async receive(from: string, message: Message) {
    runEngine.debugLog("Message Recived", message);
    if(message.getData(getName(MessageEnum.NavigationPayLoadMessage))?.showSnackbar){
      this.setState({
          CreateTeam:true,
      });
      this.handleAlertSuccessCloses()
    }
    const apiRequestCallId = message.getData(getName(MessageEnum.RestAPIResponceDataMessage));
    const responseJson = message.getData(getName(MessageEnum.RestAPIResponceSuccessMessage));
    const errorResponse = message.getData(getName(MessageEnum.RestAPIResponceErrorMessage)); 

    if (message.id === getName(MessageEnum.AccoutLoginSuccess)) {
      let value = message.getData(getName(MessageEnum.AuthTokenDataMessage));

      this.showAlert(
        "Change Value",
        "From: " + this.state.txtSavedValue + " To: " + value
      );

      this.setState({ txtSavedValue: value });
    }
   
    if (apiRequestCallId === this.getTeamCollectionCallId) {
      this.TeamListRes(responseJson)
      this.parseApiCatchErrorResponse(errorResponse);
    }else if(apiRequestCallId===this.getAdvanceSearchApiCallIds){
   this.setState({filteredContentData:responseJson.data})
}
else if(apiRequestCallId===this.getDeleteApiCallIds){
  this.getDeleteRes(responseJson);
}

  }
  async componentDidMount() {
    super.componentDidMount();
    this.getTeamCollection();
  }
  txtInputWebProps = {
    onChangeText: (text: string) => {
      this.setState({ txtInputValue: text });
    },
    secureTextEntry: false,
  };

  txtInputMobileProps = {
    ...this.txtInputWebProps,
    autoCompleteType: "email",
    keyboardType: "email-address",
  };

  txtInputProps = this.isPlatformWeb()
    ? this.txtInputWebProps
    : this.txtInputMobileProps;

  btnShowHideProps = {
    onPress: () => {
      this.setState({ enableField: !this.state.enableField });
      this.txtInputProps.secureTextEntry = !this.state.enableField;
      this.btnShowHideImageProps.source = this.txtInputProps.secureTextEntry
        ? imgPasswordVisible
        : imgPasswordInVisible;
    },
  };

  btnShowHideImageProps = {
    source: this.txtInputProps.secureTextEntry
      ? imgPasswordVisible
      : imgPasswordInVisible,
  };

  btnExampleProps = {
    onPress: () => this.doButtonPressed(),
  };

  doButtonPressed() {
    let msgSuccess = new Message(getName(MessageEnum.AccoutLoginSuccess));
    msgSuccess.addData(
      getName(MessageEnum.AuthTokenDataMessage),
      this.state.txtInputValue
    );
    this.send(msgSuccess);
  }

  // web events
  setInputValue = (text: string) => {
    this.setState({ txtInputValue: text });
  };

  setEnableField = () => {
    this.setState({ enableField: !this.state.enableField });
  };
  sortUsers = (dataType: string, keyType: string, sortType: string) => {
    const sortedUsers = sortTableData(
      this.state.TeamList,
      dataType,
      keyType,
      sortType,
    );
    this.setState({ TeamList: sortedUsers });
  };
  getTeamCollection=()=>{
    const header = {
      token: localStorage.getItem("token"),
    };
    const requestMessage = new Message(
      getName(MessageEnum.RestAPIRequestMessage)
  
    );
    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestHeaderMessage),
      JSON.stringify(header)
    );
    this.getTeamCollectionCallId = requestMessage.messageId
    requestMessage.addData(
      getName(MessageEnum.RestAPIResponceEndPointMessage),
      `${configJSON.TeamApiEndPoint}?per=&page=undefined?search=${this.state.AdvanceSearchValue}`
    );
    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestMethodMessage),
      configJSON.validationApiMethodType
    );
    runEngine.sendMessage(requestMessage.id, requestMessage);
    return requestMessage.messageId;
  }
  TeamListRes=(responseJson:any)=>{
  this.setState({TeamList:responseJson.data.reverse(),branchName:responseJson.primary_branch})
  }
  RowClick=(user:any)=>{
    this.setState({TeamId:user.id,BranchModal:true,TeamName:user.attributes?.name,
    Branches:user.attributes?.branches})
  }
  getDeleteRes=(responseJson:any)=>{
    this.setState((prev) => ({
      ...prev,
      deletealert: {
        isOpen: true,
        msg:responseJson.message,
        type:"success"
      },
      DeletePopup:false
    }));
  this.handleAlertSuccessClose()
  this.getTeamCollection();
  }
  handleAlertSuccessCloses=()=>{
    setTimeout(() => {
      this.setState({CreateTeam:false});
    }, 2000);
  }
  handleCancel=()=>{
 
  this.setState({BranchModal:false})
  }
  handleTeamPopup=(id:any)=>{
    this.setState({BranchModal:false , DeletePopup:true,DeleteId:id}) 
  }
  handleDelete=(id:any)=>{
    const header = {
      token: localStorage.getItem("token"),
    };
    const requestMessage = new Message(
      getName(MessageEnum.RestAPIRequestMessage)
  
    );
    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestHeaderMessage),
      JSON.stringify(header)
    );
    this.getDeleteApiCallIds = requestMessage.messageId
    requestMessage.addData(
      getName(MessageEnum.RestAPIResponceEndPointMessage),
      `${configJSON.TeamApiEndPoint}/${id}`
    );
    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestMethodMessage),
      configJSON.delete
    );
    runEngine.sendMessage(requestMessage.id, requestMessage);
    return requestMessage.messageId;
  
  }
  handleAlertSuccessClose=()=>{
    setTimeout(() => {
      this.setState((prev) => ({
        ...prev,
        deletealert: {
          isOpen: false,
        },
      }));
    }, 3000);
  }
  cancelContentDeleteDialog=()=>{
    this.setState({DeletePopup:false})
  }
  getAdvancedSearchLists=()=>{
    const header = {
      token: localStorage.getItem("token"),
    };
    const requestMessage = new Message(
      getName(MessageEnum.RestAPIRequestMessage)
  
    );
    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestHeaderMessage),
      JSON.stringify(header)
    );
    this.getAdvanceSearchApiCallIds = requestMessage.messageId
    requestMessage.addData(
      getName(MessageEnum.RestAPIResponceEndPointMessage),
      `${configJSON.AdvancedSearchEndPoint}?type=team&search=${this.state.AdvanceSearchValue}`
    );
    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestMethodMessage),
      configJSON.validationApiMethodType
    );
    runEngine.sendMessage(requestMessage.id, requestMessage);
    return requestMessage.messageId;
  
  }
  handleCreateTeam=()=>{
    const message = new Message(getName(MessageEnum.NavigationMessage));
    message.addData(getName(MessageEnum.NavigationTargetMessage), "CreateTeam");
    message.addData(getName(MessageEnum.NavigationPropsMessage), this.props);
    this.send(message);
  }
  handleTeamInputChangeSearch = (event:any) => {
    this.setState({AdvanceSearchValue:event.target.value},() => {
      this.getTeamCollection()
    })
      this.setState({showContentLists:event.target.value !== ""},()=>{this.getAdvancedSearchLists()}) 
     }
     handleThumbnailListClick=(item:string)=>{
      this.setState({
        AdvanceSearchValue: item,
        showContentLists: false
      },()=>{
         this.getTeamCollection()
      })
     }
  handleFilterDropClick=()=>{

  }
 
  HandleselectBranch=(index:number)=>{
    const id=this.state.Branches?.filter((i:any,inx:number)=>(inx===index))
      this.setState({branchSelect:id[0]})
    
  }
}

// Customizable Area End
