// Customizable Area Start
import React from "react";
import { IBlock } from "../../../framework/src/IBlock";
import { Message } from "../../../framework/src/Message";
import { BlockComponent } from "../../../framework/src/BlockComponent";
import MessageEnum, {
  getName,
} from "../../../framework/src/Messages/MessageEnum";
import { runEngine } from "../../../framework/src/RunEngine";
import { toast } from "react-toastify";
import { apiCall } from "../../utilities/src/NetworkRequest";

const configJSON = require("./config.js");

export interface CheckboxSelectedListType { value: string, id: string }

interface UserTeams {
  id: string,
  type: string,
  attributes: {
    id: number,
    name: string,
    description: null,
    is_selected: boolean,
    manager: string,
    users_count: string
  };
}

export interface UserResponse {
  id: string;
  attributes: UserAttributes;
}

export interface UserAttributes {
  id: number;
  first_name: string | null;
  last_name: string | null;
  middle_name: string | null;
  email: string | null;
  phone_number: number;
  country_code: number;
  prefix_title: string | null;
}


export interface Props {
  navigation: any;
  history: any;
  location: any;
  classes: any;
}

interface S {
  isLoading: boolean;
  firstName: string;
  middleName: string;
  lastName: string;
  title: string;
  email: string;
  phoneNumber: number;
  countryCode: number;
  UserData: Array<UserResponse>;
  openUserDeleteDialog: boolean;
  status: string,
  dropdownAddTeams: boolean
  teamsSelectedItems: Array<CheckboxSelectedListType>;
  teamsListing: Array<UserTeams>;
  placeholderSearchTeamsText: string;
  anchorEl: HTMLElement | null;
  teamParams: string;
  userId: number
}

interface SS {
  id: any;
}

export default class UserManageController extends BlockComponent<
  Props,
  S,
  SS
> {
  handleSetSubmittingCallback: Function = () => { };
  userListApiCallId: string = "";
  deleteUserDataApiCallId: string = "";
  getTeamListApiCallId: string = "";

  private childRef: React.RefObject<any>;
  constructor(props: Props) {
    super(props);
    this.receive = this.receive.bind(this);
    this.subScribedMessages = [
      getName(MessageEnum.AccoutLoginSuccess),
      getName(MessageEnum.RestAPIResponceMessage),
      getName(MessageEnum.SessionSaveMessage),
      getName(MessageEnum.SessionResponseMessage),
    ];

    this.state = {
      isLoading: false,
      firstName: "",
      middleName: "",
      lastName: "",
      title: "",
      email: "",
      phoneNumber: 0,
      countryCode: 0,
      UserData: [],
      openUserDeleteDialog: false,
      status: "",
      dropdownAddTeams: false,
      teamsSelectedItems: [],
      teamsListing: [],
      placeholderSearchTeamsText: "",
      anchorEl: null,
      teamParams: "",
      userId: 0,
    };
    this.childRef = React.createRef();
    runEngine.attachBuildingBlock(this as IBlock, this.subScribedMessages);
  }

  async componentDidMount(): Promise<void> {
    let userDataId = this.props?.navigation?.getParam('id')
    this.getUserList(Number(userDataId));
    this.getTeamsListings();
  }

  async receive(from: string, message: Message) {
    if (getName(MessageEnum.RestAPIResponceMessage) === message.id) {
      const responseJson = message.getData(
        getName(MessageEnum.RestAPIResponceSuccessMessage)
      );
      const apiRequestCallId = message.getData(
        getName(MessageEnum.RestAPIResponceDataMessage)
      );
      if (apiRequestCallId === this.deleteUserDataApiCallId) {
        toast.success("Account deleted successfully");
        this.props.navigation.navigate("UserManagement");
      }
      if (apiRequestCallId === this.userListApiCallId) {
        this.setState({
          UserData: responseJson.data,
          userId: responseJson.data.attributes.id,
          middleName: responseJson.data.attributes.middle_name,
          firstName: responseJson.data.attributes.first_name,
          title: responseJson.data.attributes.prefix_title,
          lastName: responseJson.data.attributes.last_name,
          countryCode: responseJson.data.attributes.country_code,
          email: responseJson.data.attributes.email,
          phoneNumber: responseJson.data.attributes.phone_number,
          status: responseJson.data.attributes.status,
          isLoading: false
        })
      } else if (this.getTeamListApiCallId === apiRequestCallId) {
        this.setState({
          teamsListing: responseJson.data
        })
      }
    }
  }

  handleEditUsersData = (id: number) =>{
    this.props.navigation.navigate("EditUser", {id: id});
  }

  getUserList = async (userId: number) => {
    this.setState({ isLoading: true });
    this.userListApiCallId = await apiCall({
      contentType: configJSON.apiContentType,
      method: configJSON.getApiMethod,
      endPoint: `${configJSON.userListEndPoint}/${userId}`,
    });
  };

  cancelLessonDeleteDialog = () => {
    this.setState({ openUserDeleteDialog: !this.state.openUserDeleteDialog })
  }

  handleDeleteUserData = () => {
    this.deleteUserValue();
  }

  deleteUserValue() {
    let userData = this.props?.navigation?.getParam('id')
    this.deleteUserData({
      content_Types: configJSON.validationApiContentType,
      method: configJSON.deleteApiMethod,
      end_Point: `${configJSON.userListEndPoint}/${userData}`,
    });
  }

  deleteUserData(data: {
    content_Types?: string;
    end_Point?: string;
    method?: string;
    body?: {};
    type?: string;
  }) {
    const { content_Types, method, end_Point } = data;
    const header = {
      "Content-Type": content_Types,
      token: localStorage.getItem("token"),
    };
    const requestMessages = new Message(
      getName(MessageEnum.RestAPIRequestMessage)

    );
    requestMessages.addData(
      getName(MessageEnum.RestAPIRequestHeaderMessage),
      JSON.stringify(header)
    );
    this.deleteUserDataApiCallId = requestMessages.messageId
    requestMessages.addData(
      getName(MessageEnum.RestAPIResponceEndPointMessage),
      end_Point
    );
    requestMessages.addData(
      getName(MessageEnum.RestAPIRequestMethodMessage),
      method
    );
    runEngine.sendMessage(requestMessages.id, requestMessages);
    return requestMessages.messageId;
  }

  toggleDropdown = () => {
    this.setState(prevState => ({
      dropdownAddTeams: !prevState.dropdownAddTeams
    }));
  }

  handleTeamsSearchChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    this.setState({
      placeholderSearchTeamsText: event.target.value,
    })
  }

  tagsCheckboxChangeHandler = (event: React.ChangeEvent<HTMLInputElement>, teamId: string) => {
    const { checked, name } = event.target;
    if (checked) {
      this.setState(prevState => ({
        teamsSelectedItems: [...prevState.teamsSelectedItems, { id: teamId, value: name }]
      }));
    } else {
      this.setState(prevState => ({
        teamsSelectedItems: prevState.teamsSelectedItems.filter(team => team.id !== teamId)
      }));
    }
  }

  dropdownHandlerClose = () => {
    this.setState({
      anchorEl: null,
      dropdownAddTeams: false,
    })
  }

  getTeamsListings = () => {
    const header = {
      token: localStorage.getItem("token"),
    };
    const requestMessage = new Message(
      getName(MessageEnum.RestAPIRequestMessage)

    );
    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestHeaderMessage),
      JSON.stringify(header)
    );
    this.getTeamListApiCallId = requestMessage.messageId
    requestMessage.addData(
      getName(MessageEnum.RestAPIResponceEndPointMessage),
      `${configJSON.teamListingApiEndPoint}?search=${this.state.placeholderSearchTeamsText}`
    );
    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestMethodMessage),
      configJSON.validationApiMethodType
    );
    runEngine.sendMessage(requestMessage.id, requestMessage);
    return requestMessage.messageId;
  }

  handleClearTeamNames = (team: CheckboxSelectedListType) => {
    this.setState((prevState) => ({
      teamsSelectedItems: prevState.teamsSelectedItems.filter(t => t.id !== team.id)
    }));
  };

}
// Customizable Area End