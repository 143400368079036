import React from 'react';
import { Typography, Button, Box, styled, DialogTitle, Dialog, TableHead, TableRow, TableCell, Badge, TableSortLabel, Checkbox, TableBody, Popover} from '@material-ui/core';
import CourseCreationController, { AttributesType, Props } from './CourseCreationController.web';

import { NewFilter, NotFoundImage, Filter } from '../src/assets';
import './CourseCreation.css';
import { BackArrow, UnlinkIcon} from './assets';
import { DownArrowIcon, UpArrowIcon, editNew} from "../../../components/src/Table/asset";
import AdvancedSearch from "../../../blocks/advancedsearch/src/AdvancedSearch.web";
import FilterItems from "../../filteritems/src/FilterItems.web";
import DeleteModal from "../../../components/src/DeleteDialog/DeleteModal";

const CreateNewVideoComponent = styled(Typography)({
  margin: 'auto',
  fontStyle: 'normal',
  display: 'flex',
  fontWeight: 600,
  fontSize: '16px',
  lineHeight: '16px',
  textAlign: 'center',
  color: '#FFFFFF',
  textTransform: 'capitalize',
});

const webStyle = {
  AccordionSummary: {
    height: '42px',
    backgroundColor: '#6C328B',
    borderRadius: '12px',
    width: '165px',
    padding: "12px 24px 12px 16px",
    marginTop: '32px',
  },
  statusNew: {
    display: 'flex',
    width: "96px",
    height: "40px",
    padding: "6px 9px",
    justifyContent: "center",
    alignItems: "center",
    gap: "10px",
    borderRadius: "88px",
    background: "#EEE8F2",
    color: "#652786",
    textTransform:"capitalize" as 'capitalize'
    
  },
  statusDraft: {
    display: 'flex',
    borderRadius: "88px",
    padding: "6px 9px",
    textTransform:"capitalize" as 'capitalize',
    justifyContent: "center",
    gap: "10px",
    background: "#FFEDE4",
    color: "#E47004",
    width: "96px",
    height: "40px",
    alignItems: "center",
  },
  statusArchived: {
    display: 'flex',
    padding: "6px 9px",
    borderRadius: "88px",
    justifyContent: "center",
    alignItems: "center",
    textTransform:"capitalize" as 'capitalize',
    gap: "10px",
    background: "#FFD9DF",
    color: "#E70E6B",
    width: "96px",
    height: "40px",
  },
};

const FiterImage = styled("img")({
});
interface HeadCell {
    id: keyof AttributesType;
    label: string;
  }

const headCells: HeadCell[] = [
    { id: "title", label: "Course" },
    { id: "course_id", label: "ID" },
    {
      id: "xpert_name",
      label: "Xpert Name",
    },
    {
      id: "content_roles",
      label: "Content Role(s)",
    },
    {
      id: "total_chapters",
      label: "Lessons",
    },
    {
      id: "user_enrolled",
      label: "Users Enrolled",
    },
    {
      id: "updated_at",
      label: "Last Day Modified"
    },
    { id: "status", label: "Status" },
  ];
  
const NoDataImg = styled("img")({
        width: "616 !important",
        height: "404px !important"
});
const BadgeComponents = styled(Badge)({
    "& .MuiBadge-anchorOriginTopRightRectangle": {
      top: "-14px",
      left: "20px",
      right: " 0px",
      backgroundColor: " #FF883F",
      fontSize: "81px",
      width: "10px",
      height: "10px",
      borderRadius: "20px"
    }
  });
  const StyledContentWrapper = styled(Box)({
    position: "relative",
    display: "flex",
    maxWidth: "520px",
    width: "100%",

  "& .filterIconBox": {
    display: "flex",
    justifyContent: "center",
    alignItems: "center",
    backgroundColor: "#FAFAFA",
    minWidth: "62px",
    borderRadius: "8px",
    borderTopLeftRadius: "8px !important",
    borderBottomLeftRadius: "8px !important",
    cursor: "pointer",
    border: "1px solid #C9C6C5",
    position: "relative",
    zIndex: 999,
  },
  "& .filterIconBox:hover": {
    borderColor: '#652786'
  },
  "& .filterBorder": {
    borderColor: '#652786',
    backgroundColor: "#EEE8F2",
  },
});

class ProgramContent extends CourseCreationController {
  constructor(props: Props) {

    super(props);
  }

  async componentDidMount() {
    super.componentDidMount();
    const urlPath = window.location.pathname;
    const id = urlPath.split('/').pop(); // Extracts the last part of the path

    if (id) {
      this.getProgramDetail(id);
    }
  }

  renderCourseButton = () => {
    if (this.state.coursesSelected) {
      return (
        <Button
          onClick={() => this.handleCourseOpen()}
          style={webStyle.AccordionSummary}
          data-test-id="panel1a-content"
          aria-controls="panel1a-content"
          id="panel1a-header"
        >
          <CreateNewVideoComponent>
            <span
              style={{
                fontSize: "16px",
                fontWeight: 600,
                paddingRight: "10px",
                fontFamily: "Poppins, sans-serif",
              }}
            >
              +
            </span>
            Add Course
          </CreateNewVideoComponent>
        </Button>
      );
    }
    return null;
  };  

  renderContent = () => {
    const { coursesSelected, selectedCourseRows } = this.state;  
    if (!coursesSelected) {
      return (
        <Box
          style={{
            display: "flex",
            justifyContent: "center",
            flexDirection: "column",
            alignItems: "center",
            height: "695px",
          }}
        >
          <Box
            style={{
              display: "flex",
              justifyContent: "center",
              flexDirection: "column",
              alignItems: "center",
              rowGap: "32px",
            }}
          >
            <NoDataImg src={NotFoundImage} data-test-id="filterOpenTestID" />
            <Typography className="no-content">
              You dont have any content yet
            </Typography>
          </Box>
          <Button
            onClick={() => this.handleCourseOpen()}
            style={webStyle.AccordionSummary}
            data-test-id="panel1a-content"
            aria-controls="panel1a-content"
            id="panel1a-header"
          >
            <CreateNewVideoComponent>
              <span
                style={{
                  fontSize: "16px",
                  fontWeight: 600,
                  paddingRight: "10px",
                  fontFamily: "Poppins, sans-serif",
                }}
              >
                +
              </span>
              Add Course
            </CreateNewVideoComponent>
          </Button>
        </Box>
      );
    } else {
      return (
        <>
        <TableBody style={{ overflowY: "auto", display: "block" }}>
          {selectedCourseRows?.map((row, index) => (
            <Box key={index} sx={{ mb: 1.25 }}>
              <TableRow
                key={index}
                hover
                style={{
                  borderRadius: "12px",
                  backgroundColor: "inherit",
                  padding: "18px 32px",
                  width: "100%",
                  display: "flex",
                  justifyContent: "space-between",
                  boxShadow: "1px 2px 10px 0px #00000011",
                }}
              >
                <TableCell
                  style={{
                    fontWeight: 600,
                    padding: "0 !important",
                    fontSize: "18px",
                    color: "#484646",
                    display: "flex",
                    border: "none",
                    gap: "30px",
                  }}
                >
                  {row.name}
                  <div
                    style={{
                      display: "flex",
                      ...(this.getStatusStyle(row.status)),
                      fontWeight: 400,
                      fontSize: "12px",
                      width: "96px",
                      height: "40px",
                      borderRadius: "88px",
                      justifyContent: "center",
                      alignItems: "center",
                      maxWidth: "300px",
                    }}
                  >
                    {this.toPascalCase(row.status)}
                  </div>
                </TableCell>
                <Box
                  style={{
                    display: "flex",
                    gap: "30px",
                    minWidth: "59px",
                    flexDirection: "row",
                  }}
                >

                  <TableCell  style={{ border: "none" }}>
                    
                    <img
                      src={editNew}
                      style={{ width: "24px", height: "24px", marginRight: "30px" }}
                    />

                    <Button                                  
                     
                     onClick={() => this.handleDetach(row.name)}
                    >
                        <img
                          src={UnlinkIcon}
                          style={{ width: "24px", height: "24px" }}
                          alt="Unlink Icon"
                        />
                    </Button>
                  </TableCell>
                </Box>
              </TableRow>
            </Box>
          ))}
        </TableBody>
        <DeleteModal
        data-test-id="Phase_modal"
        openDialog={this.state.RemovePhase}
        headingText={`Are you sure you want to detach ${this.state.selectedCourseName}?`}
        subHeading={"The course will no longer be linked to this program but can still be accessed directly."}
        btnCancelText={"Cancel"}
        btnOkText={"Detach From Program"}
        handleCancel={this.handlePhaseCancel}
        handleOk={this.handleRemovePhase}
      />
      </>
      );
    }
  };
  
  getMinWidthByIndex = (index:number) => {
    if (index === 0) return '162px';
    if (index === 1) return '120px';
    if (index === 2) return '152px';
    if (index === 3) return '169px';
    return '100%';
  };
  getId = (index: number) => {
    return index === 4 ? "last" : "";
  }  
  getClassName = (index: number) => {
    return index === 0 ? "first" : "all";
  }
  renderFilterIcon = () => {
    const { anchorFilterContainerEl } = this.state; // or props if it's passed as a prop
  
    if (anchorFilterContainerEl) {
      return (
        <FiterImage
          src={Filter}
          data-test-id="filterOpenTestID"
          alt="Filter"
        />
      );
    } else {
      return (
        <img
          src={NewFilter}
          data-test-id="filterOpenTestID"
          alt="New Filter"
        />
      );
    }
  };  
  getBorderRadius = (index:number) => {
    const { selectedRows } = this.state;
    return selectedRows.includes(index) ? '12px' : '0px';
  }
  getBackgroundColor = (index:number) => {
    const { selectedRows } = this.state;
    return selectedRows.includes(index) ? '#EEE8F2' : 'inherit';
  }
shouldDisplaySortArrows = (index: number): boolean => {
    return ![4, 5, 7].includes(index);
  };
  
  render() {
    const { anchorFilterContainerEl, courseResponse, selectedRows,coursesSelected } = this.state;
    const isNoRowPresent = !coursesSelected;
    const isSelectButtonDisabled = selectedRows.length === 0;
    const openFilterContainer = Boolean(this.state.anchorFilterContainerEl)  
    return (
      <>
      <div
       style={{
        display: 'flex',
        justifyContent: 'space-between',
        padding: '24px',
        boxShadow: this.state.coursesSelected ? '1px 2px 10px 0px #00000011' : '',
        borderRadius: '18px'
      }}>
        <Box style={{
          display: "flex",
          justifyContent: "start",
          flexDirection: "row",
          alignItems: "center",
          columnGap: "8px",
          padding: "24px",
        }}>
                <Typography className='program-title' data-test-id="program-title">
                    Program Title: {this.state.programTitle}
                </Typography>
            <div style={{ display: "flex", justifyContent: "space-between", alignItems: "center", maxWidth: "300px" }}>
            <div style={this.statusOptionData(this.state.programStatus)}
                    >
                    {this.state.programStatus}
                </div>
            </div>
        </Box>
        {this.renderCourseButton()}
        </div>
        {this.renderContent()}
      <Dialog
          open={this.state.showCoursePopup}
          aria-labelledby="alert-dialog-title"
          aria-describedby="alert-dialog-description"
          className='popup-dialog'
        >
            <Box style={{display:"flex", gap:"28px", flexDirection:"column"}}>
          <DialogTitle id="alert-dialog-title" style={{ padding: "0" }}>
            <Box className="heading_course_wrapper">
                <span>
                  <img src={BackArrow} alt="back_ing" onClick={this.handlePopupClose} />
                </span>
                <p className="heading_text">Select courses</p>
            </Box>
          </DialogTitle>
          <StyledContentWrapper  
         >
            <AdvancedSearch
              data-test-id="btn_change"
              isFilterDropActive={openFilterContainer}
              handleListItemClick={this.handleListItemClickSuggestion}
              searchInputValue={this.state.searchtext}
              handleInputChange={this.handleInputProgramChangeSearch}
              filterList={this.state.filteredList}
              showList={this.state.showList} navigation={undefined} id={""} />
            <div style={{ marginLeft: "0px" }} data-test-id="filter-drop" className={anchorFilterContainerEl ? "filterIconBox filterBorder" : "filterIconBox"}  onClick={this.handleFilterDrop} aria-describedby={"myPopover"}>
              {this.state.creationDate.length > 0 || (this.state.creationDateParams.hasOwnProperty('start_date') && this.state.creationDateParams.start_date.length > 0) ||this.state.categorySelectedItems.length > 0|| this.state.placeholderSelectedItems.length > 0  || this.state.contentRoleSelectedItems.length > 0 || this.state.selectedStatus.length > 0 ? (
                <BadgeComponents color="secondary" variant="dot"></BadgeComponents>) : (
                ""
              )
              }
            {this.renderFilterIcon()}
            </div>
            <Popover
              data-test-id="filterPropID"
              id="myPopover"
              open={openFilterContainer}
              className="filterContainerPopover"
              onClose={this.filterContainerDropdownCloseHandler}
              anchorEl={this.state.anchorFilterContainerEl}
              transformOrigin={{
                vertical: "top",
                horizontal: "right"
              }}
              anchorOrigin={{
                vertical: "bottom",
                horizontal: "right"
              }}
            >
                 <FilterItems
                data-test-id="FilterItemsTestID"
                isContentRole={this.state.contentRoleTrue}
                selectedStatus={this.state.selectedStatus}
                categorySelectedItems={this.state.categorySelectedItems}
                contentRoleSelectedItems={this.state.contentRoleSelectedItems}
                categorySearchText={this.state.categorySearchText}
                handleChangeStatus={this.handleCatalogueChangeStatus}
                handleContentRoleSearchChange={this.handleContentRoleChangeSearch}
                handleCategorySearchChange={this.handleCategoryChangeSearch}
                contentRoleSearchText={this.state.contentRoleSearchText}
                placeholderSelectedItems={this.state.placeholderSelectedItems}
                placeholderCheckboxChangeHandler={this.placeholderCheckboxAssignedExpertChangeHandler}
                handlePlaceholderSearchChange={this.handlePlaceholderAssignedSearchChange}
                contentRoleCheckboxChangeHandler={this.contentRoleCheckboxHandleChange}
                creationDateChangeHandler={this.creationChangeHandlerDate}
                dropdownOpenHandler={this.dropdownHandlerFilterOpen}
                dropdownCloseHandler={this.dropdownHandlerClose}
                handleClick={this.handleClickFilter}
                categoryCheckboxChangeHandler={this.categoryCheckboxHandlerChange}
                handleCalendarOpen={this.handleOpenCalendor}
                anchorEl={this.state.anchorElPopup}
                dropdownType={this.state.dropdownType}
                placeholderSearchText={this.state.placeholderSearchText}
                creationDate={this.state.creationDate}
                expertAdminList={this.state.expertAdminList}
                adminCatagoryList={this.state.adminCatagoryList}
                adminContentRolesList={this.state.adminContentRolesList}
                isCalendarOpen={this.state.isCalendarOpen}
                handleClear={this.handleClearFilter}
                handleCalendarChange={this.handleChangeCalendor} />
            </Popover>

          </StyledContentWrapper>
        </Box>
        <TableHead>
              <TableRow>
              {headCells.map((header,index) => {
                return (
                    <TableCell
                        key={index}
                        id={this.getId(index)}
                        style={{ minWidth: this.getMinWidthByIndex(index), textAlign: "center" }}
                        className={this.getClassName(index)}
                    >
                    <TableSortLabel direction={"asc"} style={{ display: "flex", flexDirection: 'row', alignItems: 'center' }}>
                      <span  style={{ display: "flex", justifyContent: "space-between", whiteSpace: "nowrap", fontWeight: 600, fontStyle: "normal", lineHeight: "24px", color: "#000", fontFamily: "Poppins, sans-serif" }}>{header.label}</span>
                      {this.shouldDisplaySortArrows(index) && (
                      <div style={{
                        display: 'flex',
                        flexDirection: 'column', 
                        marginLeft: '4px'
                      }}>
                        <img
                          src={UpArrowIcon}
                          alt="Up Arrow"
                          width="10px"
                          data-test-id={`testing${index}`}
                          style={{ paddingBottom: '5px', cursor: 'pointer' }}
                          onClick={() => this.handleSort(header.id)}
                        />
                        <img
                          src={DownArrowIcon}
                          alt="Down Arrow"
                          width="10px"
                          style={{ cursor: 'pointer' }}
                          onClick={() => this.handleSort(header.id)}
                        />
                      </div>
                      )}
                    </TableSortLabel>
                  </TableCell>
                )
              })}

            </TableRow>
        </TableHead>
        <TableBody style={{ height: '728px', overflowY: 'auto', overflowX: "hidden"}}>
        {courseResponse.map((row, index) => {
          const labelId = `checkbox-${index}`;

          return (
            <Box key={index} sx={{ mb: 1.25 }}>
            <TableRow
              key={index}
                hover
                style={{
                    overflow: 'hidden',
                    width: '1100px',
                    borderRadius: this.getBorderRadius(index),
                    backgroundColor: this.getBackgroundColor(index),
                }}
            //   aria-checked={isItemSelected}
            //   selected={isItemSelected}
            //   onClick={() => handleSelectRow(index)}
            >
             <TableCell style={{display:'flex', maxWidth:"198px",justifyContent:"space-between",alignItems:"center"}}>
  <Checkbox
  checked={selectedRows.includes(index)}
  onChange={(event) => this.handleCheckboxChange(index, row.title, row.status)(event)}
  inputProps={{ 'aria-labelledby': labelId }}
    className='popup-checkbox'
  />
  <img
    src={row?.thumbnail_image?.url}
    alt={row.title}
    style={{ width: '50px', height: '50px', objectFit: 'cover', marginRight: '8px' }}
  />
  {row.title}
</TableCell>
              <TableCell style={{maxWidth:"120px",textAlign: "center"}}>{row.number}</TableCell>
              <TableCell style={{minWidth:"152px",textAlign: "center"}}>{row.xpert_name}</TableCell>
              <TableCell style={{maxWidth:"180px",textAlign: "center"}}>{row.content_roles}</TableCell>
              <TableCell  style={{minWidth:"48px",textAlign: "center"}}>{row.total_chapters}</TableCell>
              <TableCell style={{minWidth:"133px",textAlign: "center"}}>{row.user_enrolled}</TableCell>
              <TableCell style={{minWidth:"161px",textAlign: "center"}}>{this.formatDate(row.updated_at)}</TableCell>
              <TableCell>
                <div style={{ display: "flex", ...(this.getStatusStyle(row.status)), width: '96px',height: '40px',borderRadius: '88px',justifyContent: "center", alignItems: "center", maxWidth: "300px" }}>
                  {this.toPascalCase(row.status)} {/* Replace "Active" with PascalCase version of row.status */}
              </div>
                  </TableCell>
            </TableRow>
            </Box>
          );
        })}
      </TableBody>
      <Box style={{ display: 'flex', justifyContent: 'center', marginTop: '16px', gap: '24px' }}>
          <Button style={{
    width: '227px',
    height: '56px',
    borderRadius: '16px',
    border: '1px solid #E47004',
    color: '#E47004',
    textTransform: 'capitalize'
  }}
  onClick={this.handlePopupClose}>
            Cancel
          </Button>
          <Button variant="contained" 
          style={{
            width: '227px',
            height: '56px',
            borderRadius: '16px',
            backgroundColor: isSelectButtonDisabled ? '#E5E2E1' : '#652786',
            color: isSelectButtonDisabled ? '#929090' : '#ffffff',
            textTransform: 'capitalize',
          }}
           onClick={this.handleSelectCourse}
           disabled={isSelectButtonDisabled}>
            Select
          </Button>
        </Box>
        </Dialog>
     <Box style={{
          display: "flex",
          justifyContent: "center",
          flexDirection: "column",
          alignItems: "center",
          columnGap: "16px"
        }}>
          <Button 
           style={{
             display: 'flex',
             width: '345px',
             height: '56px',
             justifyContent: 'center',
             alignItems: 'center',
             gap: '10px',
             backgroundColor: 'transparent', // 'none' is not valid; use 'transparent'
             borderRadius: '16px',
             border:  isNoRowPresent ? '1px solid #E5E2E1' :'1px solid #E47004',
             color: isNoRowPresent? '#929090' : '#E47004',
             marginTop: '16px',
             textTransform: 'capitalize', // Correctly typed CSS value
           }}
           disabled={isNoRowPresent}>
            Save to Draft
            </Button>
          <Button  style = {{
            display: 'flex',
            width: '345px',
            height: '56px',
            justifyContent: 'center',
            alignItems: 'center',
            gap: '10px',
            borderRadius: '16px',
            backgroundColor: isNoRowPresent ? '#E5E2E1' : '#652786',
            color: isNoRowPresent ? '#929090' : '#ffffff',
            marginTop: '16px',
            textTransform: 'capitalize',
          }}
           disabled={isNoRowPresent}>Create Program</Button>
    </Box>
      </>
    );
  }
 
    statusOptionData(row: any): React.CSSProperties | undefined {
            if(row ==="active"){
              return webStyle.statusNew
            }else if(row === "draft"){
              return webStyle.statusDraft
            }else {
              return webStyle.statusArchived
            }
          }
    };

export default ProgramContent;
